require("consolecolors");

const getElement = (id) => document.getElementById(id);

const App = () => {
	const body = document.body;
	// const hamburger = getElement("hamburger");
	// const hamburgerBtn = document.querySelector("button.hamburger");
	// const nav = getElement("nav");

	// hamburger.addEventListener("click", (event) => {
	// 	hamburgerBtn.classList.toggle("is-active");
	// 	body.classList.toggle("nav-active");
    // });
    

	// document.onkeydown = function (evt) {
	// 	evt = evt || window.event;
	// 	var isEscape = false;
	// 	if ("key" in evt) {
	// 		isEscape = (evt.key === "Escape" || evt.key === "Esc");
	// 	} else {
	// 		isEscape = (evt.keyCode === 27);
	// 	}
	// 	if (isEscape) {
	// 		document.body.classList.contains('nav-active') ? //aggiungo un controllo, perché poi mi servirò chiudere anche la modale
	// 			document.body.classList.remove('nav-active')
	// 			: null;
	// 	}
	// };

}


document.addEventListener("DOMContentLoaded", App);